import styled from 'styled-components';

const Banner = styled.section`
  background-color: #000;
  bottom: ${props => (props.willClose ? '-200px' : '0')};
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0;
  padding: 10px 30px;
  position: fixed;
  right: 0;
  transition: all 500ms;
  white-space: pre-wrap;
  width: 100%;
  z-index: 10;
  font-family: 'Raleway', sans-serif;
`;

const BannerActions = styled.div`
  button {
    margin: 5px 0;
  }
`;

const BannerText = styled.div`
  line-height: 140%;
  margin: 0;

  a {
    color: #fff;
  }
`;

const CookieModal = styled.div`
  background-color: #fff;
  margin: 30px;
  max-width: 700px;
  padding: 30px;
  position: relative;
  font-family: 'Raleway', sans-serif;
  z-index: 103;

  h1 {
    margin-top: 0;
  }
`;

const CookieModalClose = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
`;

const CookieModalWrapper = styled.section`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`;

const CookieType = styled.div`
  font-size: 1.3rem;
  margin-left: 20px;
`;

const CookieTypes = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;

  input {
    margin-left: 0;
    margin-right: 8px;
  }
`;

const CookieTypeWrapper = styled.div`
  margin-bottom: 10px;
`;

const SmallText = styled.p`
  font-size: 12px;
`;

export {
  Banner,
  BannerActions,
  BannerText,
  CookieModal,
  CookieModalClose,
  CookieModalWrapper,
  CookieType,
  CookieTypes,
  CookieTypeWrapper,
  SmallText,
};
