import { createGlobalStyle } from 'styled-components';
import themeGet from '@styled-system/theme-get';
import normalize from './Normalize';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400&display=swap');

  ${normalize}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    line-height: ${themeGet('lineHeights.normal')};
    font-family: ${themeGet('fonts.body')};
    font-size: ${themeGet('fontSizes.2')}px;
    font-weight: ${themeGet('lineHeights.normal')};
    color: #151515;
    min-width: 320px;
    direction: ltr;
    scroll-behavior: smooth;
  }

  input[type="checkbox"] {
      outline: none;
      box-shadow: none;
  }
`;

export default GlobalStyle;
