import Head from "next/head";
import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "./GlobalStyle";
import theme from "../theme";
import { initGA, logPageView } from "../utils/analytics";
import { useEffect } from "react";
import { GDPRBanner } from './gdprBanner/GdprBanner';

const PageStyle = styled.div`
  overflow: hidden;
`;

const Page = ({
  title,
  description,
  children,
  url,
  price,
  currency,
  published,
  modified,
  breadcrumbs = [],
  author,
  image,
  product = false,
  robots = true,
  type = "Article",
  additionalJSON = {},
}) => {
  const cookieTypes = {
    analytical: {
      id: 'analytical',
      title: 'Anonieme analytische cookies',
      components: [{ title: 'Google Analytics' }],
    },
    functional: {
      id: 'functional',
      title: 'Functionele cookies',
    },
  };
  
  const addJSONLD = () => {
    //create breadcrumbs
    let breadcrumbsItems = [];
    let breadcrumbObject = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [],
    };

    if (breadcrumbs && breadcrumbs.length > 0) {
      console.log(breadcrumbs);
      let i;
      for (i = 0; i < breadcrumbs.length; i++) {
        if (breadcrumbs[i].label && breadcrumbs[i].url) {
          breadcrumbsItems.push({
            "@type": "ListItem",
            position: i + 1,
            item: {
              "@id": process.env.baseUri + "/" + breadcrumbs[i].url,
              name: breadcrumbs[i].label || "",
              image: breadcrumbs[i].image || "",
            },
          });
        }
      }

      breadcrumbObject.itemListElement = breadcrumbsItems;
    }

    let jsonLDdata = {
      "@context": "http://schema.org",
      "@type": "WebPage",
      headline: title,
      image: [image],
      datePublished: published,
      dateModified: modified,
      author: {
        "@type": "Person",
        name: author,
      },
      publisher: {
        "@type": "Organization",
        name: process.env.organisation || "Placehoder",
        logo: {
          "@type": "ImageObject",
          url: process.env.logo || "",
        },
      },
      description: description,
    };

    if (product) {
      let type = "product";

      jsonLDdata = {
        ...jsonLDdata,
        "@type": type,
        brand: product.brand || process.env.organisation,
        logo: product.logo || process.env.logo || "",
        name: product.name || "",
        category: product.category || "general",
        image: product.image || "",
        description: product.description || "placeholder",
      };

      if (product.price) {
        jsonLDdata = {
          ...jsonLDdata,
          offers: {
            "@type": "Offer",
            priceCurrency: product.currency || "EUR",
            price: product.price,
            availability: "http://schema.org/InStock",
          },
        };
      }

      if (product.rating) {
        jsonLDdata = {
          ...jsonLDdata,
          aggregateRating: {
            "@type": "aggregateRating",
            ratingValue: product.rating.value || "",
            reviewCount: product.rating.count || "",
          },
        };
      }
    }

    //reference https://jsonld.com/article/
    if (additionalJSON) {
      jsonLDdata = {
        ...jsonLDdata,
        ...additionalJSON,
      };
    }

    let jsonLDObject =
      breadcrumbObject.length > 0
        ? JSON.stringify(jsonLDdata) + "," + JSON.stringify(breadcrumbObject)
        : JSON.stringify(jsonLDdata);

    return {
      __html: jsonLDObject,
    };
  };

  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  });

  return (
    <ThemeProvider theme={theme}>
      <PageStyle>
        <Head>
          <title>{title || process.env.standardTitle}</title>
          <meta
            name="description"
            content={description || process.env.standardDescription}
          />
          <meta
            name="keywords"
            content="Merlet, textielbedrukking, textieldruk, zeefdrukken, borduren, promotiekleding, promotiekledij, werkkleding, werkkledij, beroepskledij, promomateriaal, maathemden, persoonlijke beschermingsmiddelen, PBM, veiligheidsschoenen, werkschoenen, hemden, personaliseren, personalisatie"
          />
          <meta
            property="og:title"
            content={title || process.env.standardTitle}
          />
          <meta property="og:type" content={type} />
          <meta property="og:url" content={url || process.env.baseUri} />
          <meta
            property="og:image"
            content={image || process.env.socialImage}
          />
          <meta
            property="og:description"
            content={description || process.env.standardDescription}
          />
          <meta property="og:type" content="article" />

          <meta
            property="og:site_name"
            content={title || process.env.standardTitle}
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/manifest.json" />
          {price && (
            <>
              <meta property="og:price:amount" content={price} />
              <meta property="og:price:currency" content={currency || "EUR"} />
            </>
          )}
          {/* here we inject the JSON-LD from the function into a script tag  */}
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={addJSONLD()}
          />
          <script
            async
            defer
            src="https://static.cdn.prismic.io/prismic.js?new=true&repo=merlet"
          ></script>
        </Head>
        <GlobalStyle />
        <GDPRBanner cookieTypes={cookieTypes} />
        {children}
      </PageStyle>
    </ThemeProvider>
  );
};

export default Page;
