import {Box} from 'rebass/styled-components';

const MenuLeft = ({children, variant, showMenu = false, ...props}) => {
  return (
    <Box
      justifyContent="flex-start"
      flexWrap="wrap"
      alignItems="flex-start"
      py={[0]}
      variant={variant}
      display={[
        showMenu ? 'inline-block' : 'none',
        showMenu ? 'inline-block' : 'none',
        'flex',
      ]}
      {...props}
    >
      {children}
    </Box>
  );
};

export default MenuLeft;
