const UrlPrettifier = require('next-url-prettifier').default;

const routes = [
  //home
  {
    page: 'index',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl';
        case 'fr':
          return '/fr';
        case 'de':
          return '/de';
        case 'en':
          return '/en';
        default:
          return '/';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr', defaultParams: {lang: 'fr'}},
      {pattern: '/en', defaultParams: {lang: 'en'}},
      {pattern: '/de', defaultParams: {lang: 'de'}},
      {pattern: '/nl', defaultParams: {lang: 'nl'}},
      {pattern: '/', defaultParams: {lang: 'nl'}},
    ],
  },
  //kwaliteit
  {
    page: 'onze-kwaliteit',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl/onze-kwaliteit';
        case 'fr':
          return '/fr/notre-qualite';
        case 'de':
          return '/de/unser-qualitatsanspruch';
        case 'en':
          return '/en/our-quality';
        default:
          return '/onze-kwaliteit';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr/notre-qualite', defaultParams: {lang: 'fr'}},
      {pattern: '/en/our-quality', defaultParams: {lang: 'en'}},
      {pattern: '/de/unser-qualitatsanspruch', defaultParams: {lang: 'de'}},
      {pattern: '/nl/onze-kwaliteit', defaultParams: {lang: 'nl'}},
      {pattern: '/onze-kwaliteit', defaultParams: {lang: 'nl'}},
    ],
  },
  //werkwijze
  {
    page: 'onze-werkwijze',
    prettyUrl: ({lang = ''}) => {
      let result = '';
      switch (lang) {
        case 'nl':
          result = '/nl/onze-werkwijze';
          break;
        case 'fr':
          result = '/fr/notre-methode-de-travail';
          break;
        case 'de':
          result = '/de/ein-besonderes-konzept';
          break;
        case 'en':
          result = '/en/our-method';
          break;
        default:
          result = '/onze-werkwijze';
          break;
      }
      return result;
    },
    prettyUrlPatterns: [
      {
        pattern: '/fr/notre-methode-de-travail',
        defaultParams: {lang: 'fr'},
      },
      {pattern: '/en/our-method', defaultParams: {lang: 'en'}},
      {pattern: '/de/ein-besonderes-konzept', defaultParams: {lang: 'de'}},
      {pattern: '/nl/onze-werkwijze', defaultParams: {lang: 'nl'}},
      {pattern: '/onze-werkwijze', defaultParams: {lang: 'nl'}},
    ],
  },
  //totaalconcept
  {
    page: 'totaalconcept',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl/totaalconcept';
        case 'fr':
          return '/fr/concept-total';
        case 'de':
          return '/de/gesamtkonzept';
        case 'en':
          return '/en/finished-projects';
        default:
          return '/totaalconcept';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr/concept-total', defaultParams: {lang: 'fr'}},
      {pattern: '/en/finished-projects', defaultParams: {lang: 'en'}},
      {pattern: '/de/gesamtkonzept', defaultParams: {lang: 'de'}},
      {pattern: '/nl/totaalconcept', defaultParams: {lang: 'nl'}},
      {pattern: '/totaalconcept', defaultParams: {lang: 'nl'}},
    ],
  },
  //zelfbouw
  {
    page: 'zelfbouw',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl/zelfbouw';
        case 'fr':
          return '/fr/construire-soi-meme';
        case 'de':
          return '/de/eigenbau';
        case 'en':
          return '/en/diy-construction';
        default:
          return '/zelfbouw';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr/construire-soi-meme', defaultParams: {lang: 'fr'}},
      {pattern: '/en/diy-construction', defaultParams: {lang: 'en'}},
      {pattern: '/de/eigenbau', defaultParams: {lang: 'de'}},
      {pattern: '/nl/zelfbouw', defaultParams: {lang: 'nl'}},
      {pattern: '/zelfbouw', defaultParams: {lang: 'nl'}},
    ],
  },
  //contact
  {
    page: 'contact',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl/contact';
        case 'fr':
          return '/fr/contact';
        case 'de':
          return '/de/kontakt';
        case 'en':
          return '/en/contact';
        default:
          return '/contact';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr/contact', defaultParams: {lang: 'fr'}},
      {
        pattern: '/fr/transformer-vos-reves-en-un-concept-reel',
        defaultParams: {lang: 'fr'},
      },
      {pattern: '/en/contact', defaultParams: {lang: 'en'}},
      {
        pattern: '/en/ready-translate-your-dream-our-design',
        defaultParams: {lang: 'en'},
      },
      {pattern: '/de/kontakt', defaultParams: {lang: 'de'}},
      {
        pattern: '/de/wollen-sie-ihren-traum-gerne-auf-papier-sehen',
        defaultParams: {lang: 'de'},
      },
      {pattern: '/nl/contact', defaultParams: {lang: 'nl'}},
      {
        pattern: '/nl/uw-dromen-laten-vertalen-een-ontwerp',
        defaultParams: {lang: 'nl'},
      },
      {pattern: '/contact', defaultParams: {lang: 'nl'}},
      {
        pattern: '/uw-dromen-laten-vertalen-een-ontwerp',
        defaultParams: {lang: 'nl'},
      },
    ],
  },
  //boeken
  {
    page: 'boeken',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl/boeken';
        case 'fr':
          return '/fr/livres';
        case 'de':
          return '/de/bücher';
        case 'en':
          return '/en/books';
        default:
          return '/boeken';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr/livres', defaultParams: {lang: 'fr'}},
      {pattern: '/en/books', defaultParams: {lang: 'en'}},
      {pattern: '/de/bücher', defaultParams: {lang: 'de'}},
      {pattern: '/nl/boeken', defaultParams: {lang: 'nl'}},
      {pattern: '/boeken', defaultParams: {lang: 'nl'}},
    ],
  },
  //blog
  {
    page: 'blog',
    prettyUrl: ({lang = ''}) => {
      switch (lang) {
        case 'nl':
          return '/nl/blog';
        case 'fr':
          return '/fr/blog';
        case 'de':
          return '/de/blog';
        case 'en':
          return '/en/blog';
        default:
          return '/blog';
      }
    },
    prettyUrlPatterns: [
      {pattern: '/fr/blog', defaultParams: {lang: 'fr'}},
      {pattern: '/en/blog', defaultParams: {lang: 'en'}},
      {pattern: '/de/blog', defaultParams: {lang: 'de'}},
      {pattern: '/nl/blog', defaultParams: {lang: 'nl'}},
      {pattern: '/blog', defaultParams: {lang: 'nl'}},
    ],
  },
];

const urlPrettifier = new UrlPrettifier(routes);
exports.default = routes;
exports.Router = urlPrettifier;
