import Link from "next/link";
import { useState, useEffect } from "react";
import { Box, Image } from "rebass/styled-components";
import _t from "../hooks/useTranslation";
import MenuLeft from "./MenuLeft";
import MenuRight from "./MenuRight";
import HamburgerMenu from "./HamburgerMenu";
import MenuItemLeft from "./MenuItemLeft";
import MenuItemRight from "./MenuItemRight";
import Container from "./Container";
import { useScrollPosition } from "../hooks/useScrollPosition";
import { Router } from "../routes";
import Fonts from "../fonts";
import Logo from "./svg/logo";
const fonts = Fonts.getFonts();

const Header = ({ sx, switchLang = "en", currentPage = "index", ...props }) => {
  const [headerOnScroll, setHeaderOnScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isfixed = currPos.y * -1 > 50;
      setHeaderOnScroll(isfixed);
    },
    [headerOnScroll]
  );

  useEffect(() => {
    let x = window.scrollX;
    let y = window.scrollY;

    //console.log(y)

    if (y > 50) {
      setHeaderOnScroll(true);
    }
  });

  const toggleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  };

  return (
    <Box
      sx={{
        ...sx,
        position: "fixed",
        top: 0,
        zIndex: "8000",
        width: "100%",
        //bg: headerOnScroll || showMenu ? "dark" : "none",
        bg: "dark",
        transition: "all 250ms",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "darkGray",
      }}
      variant="header.main"
      {...props}
    >
      <Box
        sx={{
          bg: "white",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "gold",
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          borderTopColor: "gold",
        }}
      >
        <Container>
          <Box
            justifyContent={["center", "flex-end", "flex-end"]}
            display="flex"
            flexWrap="wrap"
            width="100%"
            variant="header.main.dark"
          >
            <MenuItemRight
              pr={[3, 0, 0]}
              pl={[3, 4, 0]}
              width="auto"
              title="HOME"
              href="/"
            ></MenuItemRight>
            <MenuItemRight
              pr={[3, 0, 0]}
              pl={[3, 4, 0]}
              width="auto"
              title="ATELIER"
              href="/atelier"
            ></MenuItemRight>
            <MenuItemRight
              pr={[3, 0, 0]}
              pl={[3, 4, 0]}
              width="auto"
              title="CONTACT"
              href="/contact"
            ></MenuItemRight>
            <MenuItemRight
              pr={[3, 0, 0]}
              pl={[3, 4, 0]}
              width="auto"
              title="NIEUWSBRIEF"
              href="/?nieuwsbrief=1"
            ></MenuItemRight>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container>
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Box display={["block", "block", "none"]} width={[1 / 2, 1 / 2, 1]} mt={2}>
              <Link href="/">
                {/*<Logo height="35px" mt={3} sx={{ transition: "all 250ms" }} />*/}
                {/* <Image
                  src="https://merletimages.imgix.net/logo.png"
                  height="35px"
                  mt={3}
                  mr={2}
                  //opacity={headerOnScroll || showMenu ? "1" : "0"}
                  sx={{ transition: "all 250ms" }}
                /> */}
                 <Logo size={50} />
              </Link>
            </Box>
            <HamburgerMenu
              width={[1 / 2, 1 / 2, 1]}
              toggle={toggleMenu}
              variant={
                /*headerOnScroll || showMenu
                ? "header.main.gold"
                : "header.main.light"*/
                "header.main.gold"
              }
            ></HamburgerMenu>
            <MenuLeft
              variant={
                /* headerOnScroll || showMenu
                ? "header.main.gold"
                : "header.main.light" */
                "header.main.gold"
              }
              alignSelf="center"
              showMenu={showMenu}
              //flexGrow={1}
              width={[1, 1, 4 / 9]}
            >
              <MenuItemLeft title="MAATHEMDEN" href="/maathemden" />
              <MenuItemLeft title="WERKKLEDING" href="/werkkleding" />
              <MenuItemLeft
                title="VEILIGHEIDSSCHOENEN"
                href="/veiligheidsschoenen"
              />
            </MenuLeft>
            <Box
              display={["none", "none", "block"]}
              width={[1, 1, 1 / 9]}
              sx={{
                textAlign: "center",
                py: 2,
              }}
            >
              <Link href="/">
                <Logo size={50} />
                {/* <Image
                  src="https://merletimages.imgix.net/logo.png"
                  height="35px"
                  my={3}
                  mr={2}
                  //opacity={headerOnScroll || showMenu ? "1" : "0"}
                  sx={{ transition: "all 250ms", cursor: "pointer" }}
                /> */}
              </Link>
            </Box>
            <MenuRight
              width={[1, 1, 4 / 9]}
              alignSelf="center"
              variant={
                /*headerOnScroll || showMenu
                ? "header.main.gold"
                : "header.main.light"*/
                "header.main.gold"
              }
              showMenu={showMenu}
              flexGrow={1}
            >
              <MenuItemRight title="PROMOTIEKLEDING">
                <Box variant="subMenu">
                  <Link href="/promotiekleding">Algemene promotiekleding</Link>
                  <Link href="/promotiekleding/zeefdruk">Zeefdruk</Link>
                  <Link href="/promotiekleding/borduren">Borduren</Link>
                  <Link href="/promotiekleding/textieltransfer">
                    Textieltransfer
                  </Link>
                </Box>
              </MenuItemRight>
              <MenuItemRight
                title="PROMOTIEMATERIAAL"
                href="/promotiemateriaal"
              ></MenuItemRight>
              <MenuItemRight title="PBM">
                <Box variant="subMenu">
                  <Link href="/pbm">Algemene PBM</Link>
                  <Link href="/pbm/mondmaskers">Mondmaskers</Link>
                  <Link href="/pbm/desinfecterend-alcohol-hand-huid">
                    Desinfecterende Alcohol - Huid
                  </Link>
                  <Link href="/pbm/desinfecterend-alcohol-oppervlaktes">
                    Desinfecterende Alcohol - Oppervlaktes
                  </Link>
                </Box>
              </MenuItemRight>
            </MenuRight>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Header;
