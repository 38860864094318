import {useState, useRef} from 'react';
import {Box, Link} from 'rebass/styled-components';

const FooterCopyright = ({children, sx, variant}) => (
  <Box
    fontSize="1"
    pb="3"
    sx={{
      textAlign: 'center',
      lineHeight: '3',
    }}
  >
    © Merlet 2020 —{' '}
    <Link href="tel:003214453200" color="gold">
      +32 14 45 32 00
    </Link>{' '}
    — info@merlet.be
  </Box>
);

export default FooterCopyright;
