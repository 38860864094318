import { Box, Link } from "rebass/styled-components";
import { GDPREditLink } from './gdprBanner/GdprBanner'

const FooterNav = ({ children, sx, variant }) => (
  <Box
    variant={variant}
    sx={{
      textAlign: "center",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "darkGray",
    }}
    pb="5"
  >
    <Box
      display={["block", "block", "inline-block"]}
      mr={["0", "0", "5"]}
      p={["2", "2", "0"]}
    >
      <Link href="/atelier">
        <a>HET ATELIER</a>
      </Link>
    </Box>
    <Box
      display={["block", "block", "inline-block"]}
      mr={["0", "0", "5"]}
      p={["2", "2", "0"]}
    >
      <Link href="/contact">
        <a>CONTACT</a>
      </Link>
    </Box>
    <Box
      display={["block", "block", "inline-block"]}
      mr={["0", "0", "5"]}
      p={["2", "2", "0"]}
    >
      <Link
        href="https://www.facebook.com/Merlet-maathemden-beroepskleding-borduurwerken-textieldruk-197267483683444/"
        target="_blank"
      >
        <a>FACEBOOK</a>
      </Link>
    </Box>
    <Box
      display={["block", "block", "inline-block"]}
      mr={["0", "0", "5"]}
      p={["2", "2", "0"]}
    >
      <Link href="/privacybeleid">
        <a>PRIVACYBELEID</a>
      </Link>
    </Box>
    <Box
      display={["block", "block", "inline-block"]}
      mr="0"
      p={["2", "2", "0"]}
    >
      <GDPREditLink title="privacy instellingen wijzigen"></GDPREditLink>
    </Box>
  </Box>
);

export default FooterNav;
