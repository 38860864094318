import React from 'react';
import * as Styled from './GdprBanner.styled';

const CookieType = ({
  cookieType,
  defaultChecked,
  defaultCookieType,
  update,
}) => {
  const updateCookie = (status) => {
    update(status);
  };

  return (
    <Styled.CookieTypeWrapper>
      <input
        defaultChecked={
          cookieType?.enforce || defaultCookieType?.enforce || defaultChecked
        }
        disabled={cookieType?.enforce || defaultCookieType?.enforce}
        onClick={e =>
          updateCookie((e.currentTarget).checked)
        }
        type='checkbox'
      />
      {cookieType?.title || defaultCookieType?.title}
      <Styled.CookieType>
        {cookieType && cookieType.components
          ? cookieType.components.map((component, i) => {
              const length =
                cookieType && cookieType.components
                  ? cookieType.components.length
                  : 0;
              return (
                <span style={{ fontSize: 12 }} key={i}>
                  {component.title}
                  {length - 1 === i ? '' : ', '}
                </span>
              );
            })
          : defaultCookieType?.components?.map((component, i) => {
              const length =
                defaultCookieType && defaultCookieType.components
                  ? defaultCookieType.components.length
                  : 0;
              return (
                <span style={{ fontSize: 12 }} key={i}>
                  {component.title}
                  {length - 1 === i ? '' : ', '}
                </span>
              );
            })}
      </Styled.CookieType>
    </Styled.CookieTypeWrapper>
  );
};

export default CookieType;
