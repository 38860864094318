import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Link } from 'rebass/styled-components';
import { ConsentContext } from '../../utils/useConsent';
import * as Styled from './GdprBanner.styled';
import CookieType from './CookieType';

const defaults = {
  accept: 'Accepteer',
  acceptAll: 'Accepteer alles & sluiten',
  change: 'Update voorkeuren',
  description:
    'Cookies zijn kleine stukjes gegevens die vanaf een website worden verzonden en door de webbrowser van de gebruiker op de computer van de gebruiker worden opgeslagen terwijl de gebruiker aan het browsen is. Uw browser slaat elk bericht op in een klein bestand, genaamd cookie. Wanneer u een andere pagina van de server opvraagt, stuurt uw browser de cookie terug naar de server. Cookies zijn ontworpen als een betrouwbaar mechanisme voor websites om informatie te onthouden of om de browse-activiteit van de gebruiker vast te leggen.',
  cookieTypes: {
    functional: {
      id: 'functional',
      title: 'Functionele cookies',
      enforce: true,
    },
  },
  policy: '/privacybeleid',
  policyText: 'privacybeleid.',
  saveSettings: 'Wijzigingen opslaan',
  text: 'Wij gebruiken cookies om onze website en onze service te optimaliseren.\nBekijk voor meer informatie het',
  title: 'Cookie Voorkeuren',
};

function GDPRBanner({ ...props }) {
  const consent = useContext(ConsentContext);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerWillClose, setBannerWillClose] = useState(false);
  const [state, setState] = useState({
    adConsentGranted: consent.adConsentGranted,
    analyticsConsentGranted: consent.analyticsConsentGranted,
    functionalityConsentGranted: consent.functionalityConsentGranted,
    personalizationConsentGranted: consent.personalizationConsentGranted,
    securityConsentGranted: consent.securityConsentGranted,
  });

  const acceptCookies = () => {
    consent.addConsent({
      adConsentGranted: 'granted',
      analyticsConsentGranted: 'granted',
      functionalityConsentGranted: 'granted',
      personalizationConsentGranted: 'granted',
      securityConsentGranted: 'granted',
    });

    setBannerWillClose(true);

    consent.closeModal();
  };

  const saveCookies = useCallback(() => {
    consent.addConsent({
      adConsentGranted: state.adConsentGranted,
      analyticsConsentGranted: state.analyticsConsentGranted,
      functionalityConsentGranted: state.functionalityConsentGranted,
      personalizationConsentGranted: state.personalizationConsentGranted,
      securityConsentGranted: state.personalizationConsentGranted,
    });

    setBannerWillClose(true);
    consent.closeModal();
  }, [state, consent, setBannerWillClose]);

  useEffect(() => {
    if (consent && consent.stateLoaded && !consent.consentGiven)
      setShowBanner(true);
  }, [consent]);

  useEffect(() => {
    if (bannerWillClose) {
      setTimeout(() => {
        setShowBanner(false);
      }, 500);
    }
  }, [bannerWillClose]);

  return (
    <>
      {showBanner && (
        <Styled.Banner willClose={bannerWillClose}>
          <Styled.BannerText>
            {props.text || defaults.text}{' '}
            <a href={props.policy || defaults.policy}>
              {props.policyText || defaults.policyText}
            </a>
          </Styled.BannerText>
          <Styled.BannerActions>
            <Button
              data-id='ignoreClickGDPR'
              onClick={e => {
                e.preventDefault();
                consent.toggleModal();
                // setShowBanner(false);
              }}
              variant="buttons.link"
              style={{ marginRight: 15 }}
            >
              {props.change || defaults.change}
            </Button>
            <Button
              onClick={e => {
                e.preventDefault();
                acceptCookies();
              }}
              variant="buttons.primary"
              style={{ textTransform: "uppercase" }}
            >
              {props.accept || defaults.accept}
            </Button>
          </Styled.BannerActions>
        </Styled.Banner>
      )}

      {consent.showConsentForm && (
        <Styled.CookieModalWrapper data-id='ignoreClickGDPR'>
          <Styled.CookieModalClose
            data-id='ignoreClickGDPR'
            onClick={consent.toggleModal}
          />
          <Styled.CookieModal data-id='ignoreClickGDPR'>
            <h1 data-id='ignoreClickGDPR'>{props.title || defaults.title}</h1>
            <Styled.SmallText data-id='ignoreClickGDPR'>
              {props.description || defaults.description}
            </Styled.SmallText>
            <Styled.CookieTypes data-id='ignoreClickGDPR'>
              {(props.cookieTypes?.functional ||
                defaults.cookieTypes?.functional) && (
                <CookieType
                  cookieType={props.cookieTypes?.functional}
                  defaultCookieType={defaults.cookieTypes?.functional}
                  defaultChecked={
                    state.functionalityConsentGranted === 'granted'
                  }
                  update={status => {
                    setState(prevState => ({
                      ...prevState,
                      functionalityConsentGranted: status
                        ? 'granted'
                        : 'denied',
                    }));
                  }}
                />
              )}
              {(props.cookieTypes?.marketing ||
                defaults.cookieTypes?.marketing) && (
                <CookieType
                  cookieType={props.cookieTypes?.marketing}
                  defaultCookieType={defaults.cookieTypes?.marketing}
                  defaultChecked={state.adConsentGranted === 'granted'}
                  update={status => {
                    setState(prevState => ({
                      ...prevState,
                      adConsentGranted: status ? 'granted' : 'denied',
                    }));
                  }}
                />
              )}
              {(props.cookieTypes?.analytical ||
                defaults.cookieTypes?.analytical) && (
                <CookieType
                  cookieType={props.cookieTypes?.analytical}
                  defaultCookieType={defaults.cookieTypes?.analytical}
                  defaultChecked={state.analyticsConsentGranted === 'granted'}
                  update={status => {
                    setState(prevState => ({
                      ...prevState,
                      analyticsConsentGranted: status ? 'granted' : 'denied',
                    }));
                  }}
                />
              )}
            </Styled.CookieTypes>
            <div data-id='ignoreClickGDPR'>
              <Button
                data-id='ignoreClickGDPR'
                onClick={saveCookies}
                variant="buttons.primary"
                style={{ marginRight: 15, marginBottom: 5 }}
              >
                {props.saveSettings || defaults.saveSettings}
              </Button>
              <Button
                variant="buttons.primary"
                data-id='ignoreClickGDPR'
                onClick={acceptCookies}
              >
                {props.acceptAll || defaults.acceptAll}
              </Button>
            </div>
            <div style={{ marginTop: '15px', opacity: 0.6, fontSize: '14px' }}>
              Cookie consent plugin by{' '}
              <a href='https://www.ismart.be' target='blank'>
                iSmart
              </a>
            </div>
          </Styled.CookieModal>
        </Styled.CookieModalWrapper>
      )}
    </>
  );
}

const GDPREditLink = ({ title }) => {
  const consent = useContext(ConsentContext);

  const updatePreferences = () => {
    consent.toggleModal();
  };

  return (
    <Link  data-id='ignoreClickGDPR'
    onClick={e => {
      e.preventDefault();
      updatePreferences();
    }} href="#">
        {title}
      </Link>

  );
};

export { GDPRBanner, GDPREditLink };
