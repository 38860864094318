import { Box } from "rebass/styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
const HamburgerMenu = ({ variant, toggle = () => {}, ...props }) => {
  return (
    <>
      <Box
        
        py={3}
        fontSize={5}
        display={["block", "block", "none"]}
        textAlign="right"
        variant={variant}
        {...props}
      >
        <FontAwesomeIcon
          icon={faBars}
          onClick={e => {
            e.preventDefault;
            toggle();
          }}
        />
      </Box>
      <Box flexBasis="100%" height="0"  display={["block", "block", "none"]}></Box>
    </>
  );
};

export default HamburgerMenu;
