export default {
    "ondek-projecten": "Découvrez nos nouveaux projets",
    "onze-realisaties-spreken": "Nos 2.600 réalisations parlent d’elles-mêmes",
    "laat-je-inspireren": "Laissez-vous inspirer",
    "bestel-onze-boeken": "Commandez un de nos livres",
    "maak-afspraak": "Prenez rendez-vous ici",
    "voornaam": "Prénom",
    "achternaam": "Nom de famille",
    "telefoon-mobiel": "Téléphone / Mobile",
    "straat-nr": "Rue + N°",
    "gemeente": "Ville / Municipalité",
    "postcode": "Code postal",
    "bericht": "Message",
    "land": "Pays",

    //buttons
    "button-bestellen": "Commander",
    "button-bericht-verzonden": "Message envoyé",
    "button-bericht-verzenden": "Envoyer message",

    //menu
    "menu.werkwijze": "Méthode de travail",
    "menu.kwaliteit": "Notre qualité",
    "menu.totaalconcept": "Concept total",
    "menu.zelfbouw": "Construire soi-même",
    "menu.blog": "Blog",
    "menu.contact": "Contact",

    //boeken
    "kies-boeken": "Choisissez un ou plusieurs livres",
    "verwijderen": "supprimer",
    "voeg-toe": "ajouter",
    "uitverkocht": "Épuisé. Ce livre n'est plus disponible'.",
    "contact-adres": "Contact et adresse de livraison",
    "bestellen": "Commander",
    "bestelling-verzonden": "Commande envoyée"
}