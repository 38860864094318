import {Box, Link, Text} from 'rebass/styled-components';
import useHover from './hooks/useHover';

const MenuItemLeft = ({children, sx, title, href, nolink, ...props}) => {
  const [menuItemHoverRef, menuItemHover] = useHover();
  return (
    <Box
      mr={[0, 0, '30px']}
      py={[1, 0]}
      width={['100%', '100%', 'auto']}
      textAlign={['center', 'center', 'left']}
      sx={{
        '&:hover': {
          bg: ['none', 'none', 'none'],
        },
        position: 'relative',
        ...sx,
      }}
      ref={menuItemHoverRef}
      {...props}
    >
      {title && !nolink && (
        <Link py={[3]} href={href}>
          {title}
        </Link>
      )}
      {title && nolink && <Text>{title}</Text>}
      {children && (
        <Box
          display={menuItemHover ? 'block' : 'none'}
          sx={{
            position: ['relative', 'relative', 'absolute'],
            top: '100%',
            left: 0,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default MenuItemLeft;
