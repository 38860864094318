import { Box, Link, Text } from "rebass/styled-components";
import useHover from "./hooks/useHover";

const MenuItemRight = ({ children, sx, title, href, nolink, ...props }) => {
  const [menuItemHoverRef, menuItemHover] = useHover();
  return (
    <Box
      ml={[0, 0, "30px"]}
      py={[1, 0]}
      width={["100%", "100%", "auto"]}
      textAlign={["center", "center", "left"]}
      sx={{
        "&:hover": {
          bg: ["none", "none", "none"],
        },
        position: "relative",
        "@media screen and (max-width: 400px)": {
          pl: 1,
          pr: 1,
          a: {
            fontSize: "9px",
          },
        },
        "@media screen and (max-width: 600px)": {
          pl: 1,
          pr: 1,
        },
        ...sx,
      }}
      ref={menuItemHoverRef}
      {...props}
    >
      {title && !nolink && (
        <Link py={[3]} href={href}>
          {title}
        </Link>
      )}
      {title && nolink && <Text>{title}</Text>}
      {children && (
        <Box
          display={menuItemHover ? "block" : "none"}
          sx={{
            position: ["relative", "relative", "absolute"],
            top: "100%",
            right: 0,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default MenuItemRight;
