import {useState, useRef} from 'react';
import {Box} from 'rebass/styled-components';
import Logo from './svg/logo';
import FooterNav from './FooterNav';
import FooterCopyright from './FooterCopyright';

const Footer = ({children, sx}) => (
  <Box
    sx={{
      bg: 'dark',
      color: 'gold',
      textAlign: 'center',
    }}
    pt="53px"
  >
    <Logo />
    <Box
      fontSize="4"
      pt="3"
      pb="4"
      mr="-15px"
      sx={{lineHeight: '1.73', fontWeight: '500', letterSpacing: '15px'}}
    >
      MERLET
    </Box>
    <FooterNav variant={'header.main.gold'} />
    <FooterCopyright />
  </Box>
);

export default Footer;
