export default {
    "ondek-projecten": "Discover our projects",
    "onze-realisaties-spreken": "More than 2,600 projects speak for themselves",
    "laat-je-inspireren": "Let us inspire you",
    "bestel-onze-boeken": "Order one of our books",
    "maak-afspraak": "Make your appointment here",
    "voornaam": "First name",
    "achternaam": "Last name",
    "telefoon-mobiel": "Phone / Mobile",
    "straat-nr": "Street + N°",
    "gemeente": "City",
    "postcode": "Zipcode",
    "bericht": "Message",
    "land": "Country",

    //buttons
    "button-bestellen": "Order",
    "button-bericht-verzonden": "Message sent",
    "button-bericht-verzenden": "Send message",

    //menu
    "menu.werkwijze": "Our method",
    "menu.kwaliteit": "Our quality",
    "menu.totaalconcept": "Finished projects",
    "menu.zelfbouw": "DIY construction",
    "menu.blog": "Blog",
    "menu.contact": "Contact",

    //boeken
    "kies-boeken": "Choose one or more books",
    "verwijderen": "delete",
    "voeg-toe": "add to order",
    "uitverkocht": "Sold-out. This book cannot be ordered anymore.",
    "contact-adres": "Contact & delivery",
    "bestellen": "Order",
    "bestelling-verzonden": "Order submitted"
}