import en from './en';
import nl from './nl';
import fr from './fr';
import de from './de';

const langObj = {
    en: en,
    nl: nl,
    fr: fr,
    de: de,
}

const getString = (langCode, reference) => {
    if (langObj[langCode] && langObj[langCode][reference]) {
        return langObj[langCode][reference];
    } 
    return false;
}

export default getString;