export default {
    "ondek-projecten": "Ontdek onze nieuwe projecten",
    "onze-realisaties-spreken": "Onze meer dan 2.600 realisaties spreken voor zich",
    "laat-je-inspireren": "Laat je inspireren",
    "bestel-onze-boeken": "Bestel één van onze boeken",
    "maak-afspraak": "Maak hier uw afspraak",
    "voornaam": "Voornaam",
    "achternaam": "Achternaam",
    "telefoon-mobiel": "Telefoon / Mobiel",
    "straat-nr": "Straat + N°",
    "gemeente": "Stad / Gemeente",
    "postcode": "Postcode",
    "bericht": "Bericht",
    "land": "Land",


    //buttons
    "button-bestellen": "Bestellen",
    "button-bericht-verzonden": "Bericht verzonden",
    "button-bericht-verzenden": "Bericht verzenden",

    //menu
    "menu.werkwijze": "Onze werkwijze",
    "menu.kwaliteit": "Onze kwaliteit",
    "menu.totaalconcept": "Totaalconcept",
    "menu.zelfbouw": "Zelfbouw",
    "menu.blog": "Blog",
    "menu.contact": "Contact",

    //boeken
    "kies-boeken": "Kies één of meerdere boeken",
    "verwijderen": "verwijderen",
    "voeg-toe": "voeg toe",
    "uitverkocht": "Uitverkocht. Dit boek kan niet meer worden nabesteld.",
    "contact-adres": "Contact & afleveradres",
    "bestellen": "Bestellen",
    "bestelling-verzonden": "Bestelling verzonden"
}